import TicketOpen from '../images/open-ticket.png';
import TicketClose from '../images/close-ticket.png';
import TicketResolved from '../images/resolved-ticket.png';

import CalendarIcon from '../images/calendar-icon.png';
import FileIcon from '../images/file-icon.png';
import LocationIcon from '../images/location-icon.png';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTicket } from '../services/lead-api';

export const Ticket = () => {
  const { id } = useParams();
  const actualUrl = window.location.href;
  const deactivatedUrl = actualUrl.includes('dev') ? 'https://dev.iamdeactivated.com' : 'https://iamdeactivated.com';

  const [ticketInfo, setTicketInfo] = useState<any>();

  useEffect(() => {
    if (id) {
      getTicket(id).then((response) => {
        response.createdDate = new Date(response.createdDate).toLocaleDateString('en-US', { dateStyle: 'full' });
        setTicketInfo(response);
      });
    }
  }, []);

  const handleClick = () => {
    window.location.href = deactivatedUrl;
  };

  const OpenTicket = () => {
    return (
      <div className="confirmed-ticket-section" id="check-ticket-page">
        <div className="ticket-container">
          <img src={TicketOpen} alt="ticket icon for confirmed ticket screen" />
        </div>
        <h5 className="ticket-customer-name">Hi {ticketInfo?.name}</h5>
        <div className="ticket-message">
          Your ticket #{ticketInfo?.numberVisit} is <span className="ticket-status">{ticketInfo?.status}</span>
        </div>
        {ticketInfo?.subject !== 'Deactivation' && (
          <div>
            <div className="ticket-deactivated">
              Please wait to be called by a steward. If you need assistance, please contact IDG at <span className="ticket-idg-number">844-335-1264</span>.
            </div>
          </div>
        )}
        <div className="ticket-information-container">
          <div className="info">
            <div style={{ marginRight: '12px' }}>
              <img src={CalendarIcon} alt="calendar" />
            </div>
            <div>
              <div className="info-title">Date:</div>
              <div className="info-description">{ticketInfo?.createdAt}</div>
            </div>
          </div>
          <div className="info">
            <div style={{ marginRight: '12px' }}>
              <img src={LocationIcon} alt="location" />
            </div>
            <div>
              <div className="info-title">Location:</div>
              <div className="info-description">{ticketInfo?.location}</div>
            </div>
          </div>
          <div className="info">
            <div style={{ marginRight: '12px' }}>
              <img src={FileIcon} alt="reason" />
            </div>
            <div>
              <div className="info-title">Reason for visit:</div>
              <div className="info-description">{ticketInfo?.reasonForVisit}</div>
            </div>
          </div>
        </div>
        {ticketInfo?.reasonForVisit === 'Deactivation' && (
          <div>
            <div className="ticket-deactivated">
              One of the stewards will call you shortly. While you wait, please file your appeal in the Deactivation support platform.
            </div>
            <button id="continue-button" className="button-original" onClick={handleClick}>
              Go To IamDeactivated
            </button>
          </div>
        )}
      </div>
    );
  };

  const CloseTicket = () => {
    return (
      <div className="confirmed-ticket-section" id="check-ticket-page">
        <div className="ticket-container">
          <img src={TicketClose} alt="ticket icon for confirmed ticket screen" />
        </div>
        <h5 className="ticket-customer-name">Hi {ticketInfo?.name} </h5>
        <div className="ticket-message">
          Your ticket #{ticketInfo?.numberVisit} for <span className="ticket-status">{ticketInfo?.reasonForVisit}</span> is{' '}
          <span className="ticket-status">{ticketInfo?.status}</span>
        </div>
        <div>
          <div className="ticket-deactivated">
            Please wait to be called by a steward. If you need assistance, please contact IDG at <span className="ticket-idg-number">844-335-1264</span>.
          </div>
        </div>
        <button id="continue-button" className="button-original" onClick={() => (window.location.href = '/')}>
          Get New Ticket
        </button>
      </div>
    );
  };

  const ResolvedTicket = () => {
    return (
      <div className="confirmed-ticket-section" id="check-ticket-page">
        <div className="ticket-container">
          <img src={TicketResolved} alt="ticket icon for confirmed ticket screen" />
        </div>
        <h5 className="ticket-customer-name">Hi {ticketInfo?.name},</h5>
        <div className="ticket-message">
          Your ticket #{ticketInfo?.numberVisit} for <span className="ticket-status">{ticketInfo?.reasonForVisit}</span> is{' '}
          <span className="ticket-status">{ticketInfo?.status}</span>
        </div>
        <div>
          <div className="ticket-deactivated">
            If you need more information about your benefits, please contact IDG at <span className="ticket-idg-number">844-335-1264</span>.
          </div>
        </div>
        <div className="ticket-information-container">
          <div className="info">
            <div style={{ marginRight: '12px' }}>
              <img src={CalendarIcon} alt="calendar" />
            </div>
            <div>
              <div className="info-title">Date:</div>
              <div className="info-description">{ticketInfo?.createdAt}</div>
            </div>
          </div>
          <div className="info">
            <div style={{ marginRight: '12px' }}>
              <img src={LocationIcon} alt="location" />
            </div>
            <div>
              <div className="info-title">Location:</div>
              <div className="info-description">{ticketInfo?.location}</div>
            </div>
          </div>
          <div className="info">
            <div style={{ marginRight: '12px' }}>
              <img src={FileIcon} alt="reason" />
            </div>
            <div>
              <div className="info-title">Reason for visit:</div>
              <div className="info-description">{ticketInfo?.reasonForVisit}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderView = () => {
    console.log('ticketInfo', ticketInfo);
    if (ticketInfo?.status === 'Open' || ticketInfo?.status === 'In Progress') return OpenTicket();
    if (ticketInfo?.status === 'Closed') return CloseTicket();
    if (ticketInfo?.status === 'Resolved') return ResolvedTicket();
    return <></>;
  };

  return RenderView();
};
