import axios from 'axios';
import { ILead } from '../store/LeadContext';
// import moment from 'moment'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// ========= [ POST Ticket ] ========= //
export const postTicket = async (lead: Partial<ILead>) => {
  const url = '/office-visit/verify-existence';
  const documentToSend = lead.tlcNumber ? {tlcNumber: lead.tlcNumber} : {driversLicenseNumber: lead.driversLicenseNumber}
  try {
    const { data } = await api.post(url, {
      email: lead.email,
      location: lead.location,
      reasonForVisit: lead.reasonForVisit,
      ...documentToSend,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

// ========= [ POST New Customer Ticket ] ========= //
export const postNewCustomer = async (lead: Partial<ILead>) => {
  const url = '/office-visit';
  try {
    const { data } = await api.post(url, {
      utm: lead.utm || 'WB',
      form: lead.form || '',
      email: lead.email,
      location: lead.location,
      firstName: lead.firstName,
      lastName: lead.lastName,
      gender: lead.gender,
      licenseNumber: lead.driversLicenseNumber,
      TLCNumber: lead.tlcNumber,
      phoneNumber: lead.phoneNumber,
      dateOfBirth: lead.dateOfBirth,
      reasonForVisit: lead.reasonForVisit,
      browserDevice: lead.browserDevice,
      browserLanguage: lead.browserLanguage,
      preferredLanguage: lead.preferredLanguage,
      formVariant: lead.formVariant,
      isDelivery: lead.delivery,
    });
    return data;
  } catch (e) {
    console.log('error:', e);
  }
};

export const getTicket = async (id: string) => {
  const url = `/office-visit/public/${id}`;
  return (await api.get(url)).data;
};
