export interface IInputLists {
  label: string;
  name: string;
  type: string;
  placeholder?: string;
  radioValue?: Array<string>;
  maxLength?: number;
  minLength?: number;
  info?: string;
  link?: string;
  onChange?: (value: string) => string;
}

export const acctValidatorInputs: Array<IInputLists> = [
  {
    label: 'Email',
    name: 'email',
    placeholder: 'example@domain-name.com',
    type: 'text',
  },
];

export const newTicketForm1: Array<IInputLists> = [
  {
    label: 'First Name',
    name: 'firstName',
    placeholder: 'Enter First Name',
    type: 'text',
  },
  {
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Enter Last Name',
    type: 'text',
    minLength: 2,
  },
  {
    label: 'Email',
    name: 'email',
    placeholder: 'example@domain-name.com',
    type: 'text',
  },
];

export const newTicketForm2: Array<IInputLists> = [
  {
    label: 'Mobile Number',
    name: 'phoneNumber',
    placeholder: '(###) ###-####',
    type: 'tel',
    maxLength: 14,
  },
];

export const newTicketForm3: Array<IInputLists> = [
  {
    label: 'Birthdate',
    name: 'dateOfBirth',
    placeholder: 'MM-DD-YYYY',
    type: 'text',
    maxLength: 14,
  },
  {
    label: 'Age',
    name: 'age',
    placeholder: '',
    type: 'number',
  },
];
