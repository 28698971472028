import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { LeadContext, leadInitialValue } from './store/LeadContext';
// import { ILead, LeadContext, leadInitialValue } from './common/store/LeadContext';
import { ExistingAcctValidator } from './screens/existing-acct-validator';
import { NewTicket } from './screens/new-ticket';
import { ConfirmedTicket } from './screens/confirmed-ticket';
import ReactGA from 'react-ga';
import './App.css';
import { Ticket } from './screens/ticket';

import SystemMessages from './SystemMessages';

import { APP_IS_TEMPORARILY_UNAVAILABLE } from './config';

export const App = () => {
  // const [storageData, setStorageData] = useState<Partial<ILead>>(leadInitialValue);

  const [lead, dispatch] = useState(leadInitialValue);
  document.title = 'Driver 360 tickets';

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE || '');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (APP_IS_TEMPORARILY_UNAVAILABLE) {
    return (
      <SystemMessages />
    )
  }

  return (
    <LeadContext.Provider value={{ lead, dispatch }}>
      <div className="App">
        <Routes>
          <Route path="/" element={<ExistingAcctValidator />} />
          <Route path="/ticket-registered-confirmation" element={<ConfirmedTicket />} />
          <Route path="/new-ticket-form" element={<NewTicket />} />
          <Route path="/ticket/:id" element={<Ticket />} />
        </Routes>
      </div>
    </LeadContext.Provider>
  );
};
