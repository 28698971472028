import * as Yup from 'yup';
import moment from 'moment';

export const validationSchema1 = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      {
        message: 'Invalid email',
      }
    )
    .required('Email Required'),
  doYouHaveTlc: Yup.boolean().required('Required'),
  driversLicenseNumber: Yup.string().when('doYouHaveTlc', {
    is: false,
    then: Yup.string().required('Required')
  }),
  tlcNumber: Yup.string().when('doYouHaveTlc', {
    is: true,
    then: Yup.string().required('Required')
  }),
  reasonForVisit: Yup.string().required('Reason Required'),
  otherDescription: Yup.string().when('reasonForVisit', {
    is: 'other',
    then: Yup.string().required('Required'),
  }),
});

export const validationSchema2 = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Name too short')
    .matches(/^[a-zA-Z\s-]/g, {
      message: 'Name not valid',
      excludeEmptyString: true,
    })
    .required('First name Required'),
  lastName: Yup.string()
    .min(2, 'Name too short')
    .matches(/^[a-zA-Z\s-]/g, {
      message: 'Name not valid',
      excludeEmptyString: true,
    })
    .required('Last name Required'),
  email: Yup.string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      {
        message: 'Invalid email',
      }
    )
    .required('Email Required'),
  gender: Yup.string().required('Gender Required'),
  phoneNumber: Yup.string().min(14, 'Invalid Mobile #').max(14, 'Invalid Mobile #').required('Mobile # Required'),
  dateOfBirth: Yup.string()
    .test('dateOfBirth', 'Invalid Age', function(value: string | undefined) {
      const valueArray = value?.split('-');
      if (valueArray && moment(Date.now()).diff(new Date(+valueArray[0], +valueArray[1] - 1, +valueArray[2]), 'years') < 10) {
        return false;
      } else if (valueArray && moment(Date.now()).diff(new Date(+valueArray[0], +valueArray[1] - 1, +valueArray[2]), 'years') > 100) {
        return false;
      } else if (value === 'Invalid date') {
        return false;
      } else {
        return true;
      }
    })
    .required('Required'),
  // age: Yup.number().integer('Invalid Age').positive('Invalid Age').min(10).max(100).required('Req.'),
  reasonForVisit: Yup.string().required('Reason Required'),
  preferredLanguage: Yup.string().required('Language Required'),
});
