import idgRectLogo from '../images/IDG_logo_rectangular.png';
import { ReactElement } from 'react';
import '../App.css';

export const RectangularHeader = (): ReactElement => {
  return (
    <div id="idg-logo-container">
      <img id="idg-logo-rect" src={idgRectLogo} alt="idg logo above form" />
    </div>
  );
};
