import { useFormik } from 'formik';
import { LeadContext, leadInitialValue, ILead } from '../store/LeadContext';
import React, { useContext, useState, useEffect } from 'react';
import { validationSchema2 } from '../utils/validationSchema';
import { useNavigate, useLocation } from 'react-router-dom';
import { newTicketForm1, newTicketForm2, newTicketForm3 } from '../utils/formInput-values';
import { preferredLanguageList } from '../utils/genders-languages';
import moment from 'moment';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { IoIosCalendar } from 'react-icons/io';
import { postNewCustomer } from '../services/lead-api';
import ReactGA from 'react-ga';
import { Loader } from './loader';
import dayjs from 'dayjs';

export const NewTicketForm = () => {
  const navigate = useNavigate();

  const leadContext = useContext(LeadContext);
  const formDataFromSessionStorage = sessionStorage.getItem('formData') === null ? null : JSON.parse(sessionStorage.getItem('formData') || '');
  const [submitClicked, setSubmitClicked] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const browserLang = navigator.language;
  const userAgent = navigator.userAgent;
  const { search } = useLocation();

  const sendData = async (values: Partial<ILead>) => {
    const searchParams = new URLSearchParams(search);

    values.browserDevice = userAgent;
    values.browserLanguage = browserLang;
    values.contracts = searchParams.get('contract') !== null ? [`${searchParams.get('contract')}`] : [];
    values.utm = searchParams.get('utm_source') || '';
    values.formDropoff = true;
    values.tlcNumber = formDataFromSessionStorage.tlcNumber
    values.driversLicenseNumber = formDataFromSessionStorage.driversLicenseNumber

    try {
      const newTicket = await postNewCustomer(values);
      values.ticketNumber = newTicket.numberVisit;

      if (!Object.keys(newTicket).includes('error')) {
        sessionStorage.setItem('ticketNumber', newTicket.ticketNumber);
        leadContext?.dispatch(values);
      }
    } catch (error) {
      console.log('error:', error);
    }
  };

  const formik = useFormik<Partial<ILead>>({
    initialValues: leadInitialValue,
    validationSchema: validationSchema2,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      ReactGA.event({
        category: 'Submit Button',
        action: 'Submit Button Clicked',
      });

      try {
        setShowSpinner(true);
        await sendData(values);
        sessionStorage.removeItem('formData');
        setShowSpinner(false);
        navigate('/ticket-registered-confirmation');
      } catch (error) {
        console.log('error:', error);
      }
    },
  });

  const { errors, handleSubmit, handleChange, touched, getFieldProps, isSubmitting, setFieldValue, setFieldTouched, validateField, values } = formik;

  // === [Auto Scroll to First Error Message] === //
  useEffect(() => {
    if (!Object.keys(errors).length) {
      return;
    } else if (submitClicked && errors) {
      document.getElementById(Object.keys(errors)[0])?.focus();
      window.scrollTo(0, -100 + (document.getElementById(Object.keys(errors)[0])?.offsetTop || 0));
      setSubmitClicked(false);
    }
  }, [errors, submitClicked]);

  useEffect(() => {
    if (leadContext?.lead.email === '' && formDataFromSessionStorage === null) {
      return navigate('/', { replace: true });
    }
    if (leadContext?.lead.email) {
      values.email = leadContext?.lead.email;
      values.reasonForVisit = leadContext?.lead.reasonForVisit;
      values.location = leadContext?.lead.location;
    } else if (leadContext?.lead.reasonForVisit === 'other') {
      values.otherDescription = leadContext?.lead.otherDescription;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!values.reasonForVisit || !values.email || !values.location) {
      values.email = formDataFromSessionStorage?.email;
      values.reasonForVisit = formDataFromSessionStorage?.reasonForVisit;
      values.location = formDataFromSessionStorage?.location;
    }

    if (formDataFromSessionStorage?.reasonForVisit === 'other') {
      values.otherDescription = formDataFromSessionStorage?.otherDescription;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const value = e.target.value;

    if (name === 'firstName' || name === 'lastName') {
      const filteredValue = value.replace(/[^a-zA-Z\s-]/g, '');
      setFieldValue(name, filteredValue);
      setFieldTouched(name);
      return;
    }

    if (name === 'email') {
      const filteredValue = e.target.value.replace(/[^a-zA-Z0-9-._@]/g, '');
      setFieldValue(name, filteredValue);
      setFieldTouched(name);
      return;
    }

    if (name === 'phoneNumber') {
      const filteredValue = value.replace(/[^\d]/g, '');
      const filteredValueLength = filteredValue.length;
      if (filteredValueLength < 4) {
        setFieldValue(name, filteredValue);
        setFieldTouched(name);
        validateField(name);
        return;
      }

      if (filteredValueLength < 7) {
        setFieldValue(name, `(${filteredValue.slice(0, 3)}) ${filteredValue.slice(3)}`);
        setFieldTouched(name);
        validateField(name);
        return;
      }

      setFieldValue(name, `(${filteredValue.slice(0, 3)}) ${filteredValue.slice(3, 6)}-${filteredValue.slice(6, 10)}`);
      setFieldTouched(name);
      validateField(name);
      return;
    }

    if (name === 'delivery') {
      setFieldValue(name, e.target.checked)
      setFieldTouched(name)
      return
    }
  };

  const datePickerDateHandler = (e: string | null, name: string) => {
    const internalValue = e;
    const newDateOfBirth = dayjs(internalValue).format('YYYY-MM-DD');
    const dateDisplayed = dayjs(internalValue).format('MM-DD-YYYY');

    setFieldValue(name, newDateOfBirth);
    setFieldTouched(name);
    setDatePickerValue(dateDisplayed);
  };

  const datePickerOnBlur = () => {
    const { dateOfBirth } = formik.values;
    if (dateOfBirth !== '' || 'Invalid date') {
      const years = moment().diff(dateOfBirth, 'years').toString();

      setFieldValue('age', years);
      setFieldTouched('age');
    } else {
      return;
    }
  };

  const preferredLanguageListRender = preferredLanguageList.map((option) => {
    return (
      <option key={option.value} disabled={option.disabled} value={option.value}>
        {option.label}
      </option>
    );
  });

  return (
    <>
      {showSpinner && <Loader />}
      <form className="form" onSubmit={handleSubmit}>
        {newTicketForm1.map((newTicketInput) => {
          const { label, name, placeholder, type } = newTicketInput;
          if (name !== 'email') {
            return (
              <div className="form-field" key={name} id={`${name}-container`}>
                <label htmlFor={name} id={`${name}-label`}>
                  <div className="inputLabels">
                    <p className="field">{label}</p>
                    {touched[name] && errors[name] && <span className="errorMessage">{errors[name]}</span>}
                  </div>
                  <input id={name} placeholder={placeholder} type={type} {...getFieldProps(name)} onChange={(e) => handleOnChange(e, name)} />
                </label>
              </div>
            );
          }
        })}

        <div className="form-field" id="gender-field">
          <div className="inputLabels" id="gender-label">
            <p className="field">Gender</p>
            <label htmlFor="gender">{touched?.gender && errors?.gender ? <span className="errorMessage">{errors?.gender}</span> : <></>}</label>
          </div>
          <div className="radio-buttons-section">
            <div className="radio-button-container">
              <input className="radio-button" type="radio" name="gender" id="male" value="Male" onChange={(e) => handleChange(e)} />
              <label className="radio-button-label" htmlFor="male" id="male-label">
                <span className="radio-label">Male</span>
              </label>
            </div>
            <div className="radio-button-container">
              <input className="radio-button" type="radio" name="gender" id="female" value="Female" onChange={(e) => handleChange(e)} />
              <label className="radio-button-label" htmlFor="female" id="female-label">
                <span className="radio-label">Female</span>
              </label>
            </div>
            <div className="radio-button-container">
              <input className="radio-button" type="radio" name="gender" id="preferNotToSay" value="Prefer not to say" onChange={(e) => handleChange(e)} />
              <label className="radio-button-label" htmlFor="preferNotToSay" id="preferNotToSay-label">
                <span className="radio-label">Prefer not to say</span>
              </label>
            </div>
          </div>
        </div>

        {newTicketForm2.map((newTicketInput) => {
          const { label, name, placeholder, type } = newTicketInput;
          return (
            <div className="form-field" key={name} id={`${name}-container`}>
              <label htmlFor={name} id={`${name}-label`}>
                <div className="inputLabels">
                  <p className="field">{label}</p>
                  {touched[name] && errors[name] && <span className="errorMessage">{errors[name]}</span>}
                </div>
                <input
                  id={name}
                  placeholder={placeholder}
                  type={type}
                  {...getFieldProps(name)}
                  onChange={name === 'phoneNumber' ? (e) => handleOnChange(e, name) : (e) => handleChange(e)}
                />
              </label>
            </div>
          );
        })}

        <div className="birthdate-age-container">
          {newTicketForm3.map((newTicketInput) => {
            const { label, name, placeholder, type } = newTicketInput;
            if (name === 'dateOfBirth') {
              return (
                <div className="form-field" key={name} id={`${name}-container`}>
                  <label htmlFor={name}>
                    <div className="inputLabels" id={`${name}-field-container`}>
                      <p className="field" id={`${name}-field`}>
                        {label}
                      </p>
                      {touched[name] && errors[name] && <span className="errorMessage">{errors[name]}</span>}
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <>
                        <MobileDatePicker
                          value={datePickerValue}
                          onChange={(e) => datePickerDateHandler(e, 'dateOfBirth')}
                          disableHighlightToday={true}
                          inputFormat="MM-DD-YYYY"
                          closeOnSelect={true}
                          openTo="year"
                          disableFuture={true}
                          disableMaskedInput={true} // TESTING
                          onAccept={datePickerOnBlur}
                          orientation={'portrait'}
                          renderInput={({ inputRef, inputProps }) => (
                            <div className="date-picker-container">
                              <input id="date-picker-input" ref={inputRef} {...inputProps} placeholder="Select a date" value={datePickerValue} />
                              <span className="calendar-icon-container">
                                <IoIosCalendar />
                              </span>
                            </div>
                          )}
                        />
                      </>
                    </LocalizationProvider>
                  </label>
                </div>
              );
            } else {
              return (
                <div className="form-field" key={name} id={`${name}-container`}>
                  <label htmlFor={name} id={`${name}-label`}>
                    <div className="inputLabels" id={`${name}-field-container`}>
                      <p className="field" id={`${name}-field`}>
                        {label}
                      </p>
                      {touched[name] && errors[name] && <span className="errorMessage">{errors[name]}</span>}
                    </div>
                    <input id={name} placeholder={placeholder} type={type} {...getFieldProps(name)} onChange={(e) => handleOnChange(e, name)} />
                  </label>
                </div>
              );
            }
          })}
        </div>

        <div className="form-field">
          <div className="inputLabels">
            <p className="field">Preferred Language</p>
            {touched.preferredLanguage && errors.preferredLanguage ? <span className="errorMessage">{errors.preferredLanguage}</span> : <></>}
          </div>
          <label htmlFor="preferredLanguage">
            <select id="preferredLanguage" onChange={handleChange} value={getFieldProps('preferredLanguage').value}>
              {preferredLanguageListRender}
            </select>
          </label>
        </div>

        <div id="delivery-checkbox-container">
          <div style={{ marginTop: '16px' }}>
            <label htmlFor="delivery" className="field" id="p-delivery">
              <input id="delivery" type="checkbox" onChange={(e) => handleOnChange(e, 'delivery')} />
              Delivery
            </label>
            {touched?.delivery && errors?.delivery && <div className="errorMessage">{errors?.delivery}</div>}
          </div>
        </div>

        <div className="button-container" id="submit-button-container">
          <button id="submit-button" type="submit" disabled={isSubmitting} onClick={() => setSubmitClicked(true)}>
            Submit
          </button>
        </div>
      </form>
    </>
  );
};
