import { RectangularHeader } from '../components/rectangular-header';
import { NewTicketForm } from '../components/new-ticket-form';
import { MdOutlineCameraAlt } from 'react-icons/md';
import { useEffect } from 'react';

export const NewTicket = (): any => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="form-screen" id="new-ticket-screen">
      <RectangularHeader />
      <div className="form-container">
        <div className="form-sub-container">
          <h2 id="form-title">New Ticket</h2>
          <button id="scan-license-button">
            <MdOutlineCameraAlt /> <span>Scan License</span>
          </button>
        </div>
        <NewTicketForm />
      </div>
    </div>
  );
};
