import { createContext } from 'react';
// import { string } from 'yup';

export enum FormVariantEnum {
  REACT_V1 = 'IDG-React-Ticket-Form-V1', // Ask Alex about new form variant name
  REACT_V2 = 'IDG-React-Ticket-Form-V2',
}

type FormVariant = typeof FormVariantEnum;

interface ILeadObjectKeys {
  [key: string]: string | number | boolean | Array<string> | FormVariant | undefined;
}

export interface ILead extends ILeadObjectKeys {
  firstName?: string;
  lastName: string;
  email: string;
  gender?: string;
  driversLicenseNumber?: string;
  tlcNumber?: string;
  phoneNumber?: string;
  dateOfBirth?: string;
  reasonForVisit?: string;
  preferredLanguage?: string;
  delivery?: boolean;
  formVariant?: string;
  utm?: string;
  contracts?: Array<string>;
  browserDevice?: string;
  form: string;
  status: string;
  ticketNumber?: number;
  formDropoff?: boolean;
  browserLanguage?: string;
  otherDescription?: string;
  age?: string;
  location: string;
  doYouHaveTlc?: boolean
}

export const leadInitialValue: Partial<ILead> = {
  firstName: '',
  lastName: '',
  email: '',
  gender: '',
  driversLicenseNumber: '',
  tlcNumber: '',
  phoneNumber: '',
  dateOfBirth: '',
  reasonForVisit: '',
  otherDescription: '',
  preferredLanguage: '',
  formVariant: FormVariantEnum.REACT_V1,
  utm: undefined,
  contracts: undefined,
  browserDevice: undefined,
  browserLanguage: undefined,
  delivery: false,
  form: process.env.REACT_APP_URL as string,
  formDropoff: true,
  status: 'Partial',
  ticketNumber: undefined,
  age: '',
  location: '',
  doYouHaveTlc: undefined
};

export const LeadContext = createContext<{
  lead: Partial<ILead>;
  dispatch: React.Dispatch<React.SetStateAction<Partial<ILead>>>;
} | null>(null);
