import TicketIcon from '../images/Ticket_icon.png';
import { LeadContext } from '../store/LeadContext';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const ConfirmedTicket = () => {
  const leadContext = useContext(LeadContext);
  const ticketNumber = leadContext?.lead.ticketNumber || sessionStorage.getItem('ticketNumber'); 
  const navigate = useNavigate(); 

  useEffect(() => {
    if (!Boolean(ticketNumber)) {
      navigate('/');
    }
  }, [ticketNumber, navigate]);

  return (
    <div className="confirmed-ticket-section" id="confirmed-ticket-page">
      <div className="ticket-icon-container">
        <div className="circle-number">{`${ticketNumber}`}</div>
        <img src={TicketIcon} alt="ticket icon for confirmed ticket screen" />
      </div>
      <h3>Your registration is completed!</h3>
      <p>Your ticket number is {`${ticketNumber}`}. Please wait to be called at the office</p>
    </div>
  );
};
